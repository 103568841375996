<template>
  <div class="home">
    <router-view></router-view>
    <AlTabbar /> 
  </div>
</template>

<script>
import AlTabbar from "../components/Tabbar";
export default {
  name: "home",
  components: { AlTabbar }
};
</script>
