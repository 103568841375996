<template>
  <van-tabbar v-model="active" inactive-color="#999999" active-color="#0E3592">
    <van-tabbar-item
      v-for="(item, index) in tabbars"
      :key="index"
      :to="item.name"
    >
      <span>{{ item.title }}</span>
      <img
        class="icon_tabbar"
        slot="icon"
        slot-scope="props"
        :src="props.active ? item.active : item.normal"
      />
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: "AlTabbar",
  data() {
    return {
      active: 0,
      tabbars: [
        {
          name: "/index",
          title: "首页",
          normal: require("../assets/image/home-1.png"),
          active: require("../assets/image/check-1.png"),
        },
        {
          name: "/integral/index",
          title: "商城",
          normal: require("../assets/image/home-2.png"),
          active: require("../assets/image/check-2.png"),
        },
        {
          name: "/personal/index",
          title: "个人中心",
          normal: require("../assets/image/home-3.png"),
          active: require("../assets/image/check-3.png"),
        },
      ],
    };
  },
  methods: {},
  //通过路由跳转判断选中的样式
  created() {
    if (this.$route.name == "Index") {
      this.active = 0;
    } else if (this.$route.name == "integralIndex") {
      this.active = 1;
    } else if (this.$route.name == "personal") {
      this.active = 2;
    }
  },
};
</script>

<style>
.icon_tabbar {
  width: 20px;
  height: 20px;
}
</style>
